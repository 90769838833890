import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAts060fRv3KmjqpCB8LikD9RQvvCT5-VQ",
  authDomain: "chatapp-4141.firebaseapp.com",
  projectId: "chatapp-4141",
  storageBucket: "chatapp-4141.appspot.com",
  messagingSenderId: "1077697787057",
  appId: "1:1077697787057:web:bf4b1d494c90192a8d5e90",
  measurementId: "G-7ES1F2P1VY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;